import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Privacy Policy",
  "lang": "en"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Privacy Policy`}</h1>
    <p><strong parentName="p">{`1. Information Collection`}</strong></p>
    <p>{`DP Aerogels International Pte. Ltd., located at 203 Henderson Road, #12-13, Singapore 159546 (the "Company", “we”, or “us”) is the operator of any Website published by the Company, including, but not limited to, dpaerogels.com (the “Website”).`}</p>
    <p>{`As the operator of the Website, we take the protection of your personal data very seriously. We collect, process, and use your personal data in accordance with this privacy policy and in compliance with the Personal Data Protection Act ("PDPA").`}</p>
    <p>{`This privacy policy (“Privacy Policy”) will provide you with information about the collection, processing and use of your personal data when using the Website.`}</p>
    <p>{`In case you provide us with the personal data of third persons (such as family members or work colleagues) you should make sure that these persons are familiar with this Privacy Policy and you should only share their personal data if you have permission to do so and ensure that his personal data is correct.`}</p>
    <p><strong parentName="p">{`2. Responsible Person`}</strong></p>
    <p>{`For any matters, relating to data protection you may contact `}<a parentName="p" {...{
        "href": "mailto:info@dpaerogels.com"
      }}>{`info@dpaerogels.com`}</a>{` in writing by e-mail or letter to the following address:`}</p>
    <pre><code parentName="pre" {...{}}>{`DP Aerogels International Pte. Ltd.
203 Henderson Road
#12-13
Singapore 159546
Email: info@dpaerogels.com
`}</code></pre>
    <p><strong parentName="p">{`3. Data processing in connection with the Website`}</strong></p>
    <p><strong parentName="p">{`3.1 Visiting our Website`}</strong></p>
    <p>{`When you visit our Website, the hosting provider(s) of our Website may automatically collect and store various information in server log files that your browser transmits to us. The information/data mentioned is neither assigned to specific persons nor linked to data from other sources. The following technical data may be recorded by us, as usual with every connection with a web server, without your intervention, and stored by us until automatic deletion after no later than two days:`}</p>
    <ul>
      <li parentName="ul">{`Anonymized IP addresses`}</li>
    </ul>
    <p><strong parentName="p">{`3.2 Use of Website Cookies`}</strong></p>
    <p>{`The Website may use cookies. Cookies are text files that are stored in a computer system via an Internet browser. More detailed information on cookies and how they work can be found at: `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org"
      }}>{`http://www.allaboutcookies.org`}</a>{`.`}</p>
    <p>{`Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the data subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.`}</p>
    <p>{`Through the use of cookies, the Company may provide the users of the Website with more user-friendly services that would not be possible without the cookie setting.`}</p>
    <p>{`Cookies may allow us, as previously mentioned, to recognize our Website users. The purpose of this recognition is to make it easier for users to utilize our Website. The Website user that uses cookies, e.g. does not have to enter access data each time the Website is accessed, because this is taken over by the Website, and the cookie is thus stored on the user's computer system.`}</p>
    <p>{`You may, at any time, prevent the setting of cookies through our Website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, previously set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, it may not be possible to use all the functions of our Website.`}</p>
    <p><strong parentName="p">{`3.3 Contact possibility via the Website`}</strong></p>
    <p>{`You may contact us via our Website’ contact page or by e-mail to the following e-mail address: `}<a parentName="p" {...{
        "href": "mailto:info@dpaerogels.com"
      }}>{`info@dpaerogels.com`}</a>{`. For this, we require the following information: Name, Subject, E-Mail address, message.`}</p>
    <p>{`We use this data, which you may give voluntarily, only in order to answer your contact question or to reply to your e-mail in the best possible manner.`}</p>
    <p><strong parentName="p">{`4. Other parties who have access to information we collect`}</strong></p>
    <p>{`With the exception of the provider(s) of our Website, we do not make your personal data available to third parties unless you have expressly consented to it, if we are legally obligated to, or if this is necessary to enforce our rights concerning a contractual relationship.`}</p>
    <p><strong parentName="p">{`5. Data security`}</strong></p>
    <p>{`We use appropriate technical and organizational security measures to protect your stored personal data against manipulation, partial or complete loss, and unauthorized access by third parties. Our security measures are continuously being improved in line with technical developments.`}</p>
    <p>{`Please note that any data transmission on the Internet (e.g. communication by e-mail) is generally not secure and we accept no liability for data transmitted to us via the Internet. Unfortunately, absolute protection is not technically possible.`}</p>
    <p>{`This information does not apply to the Website of third parties and the corresponding links given on our Website. The Company assumes no responsibility and liability for these.`}</p>
    <p><strong parentName="p">{`6. Your Rights regarding your data`}</strong></p>
    <p><strong parentName="p">{`6.1 Right to confirmation`}</strong></p>
    <p>{`You have the right to obtain confirmation from the Company as to whether or not personal data concerning you is being processed. If you wish to avail yourself of this right of confirmation, you may, at any time, contact the responsible person as stated in section 1 of this Privacy Policy.`}</p>
    <p><strong parentName="p">{`6.2 Right to access`}</strong></p>
    <p>{`You have the right to obtain from the Company free information about your personal data stored at any time and a copy of this information. Furthermore, you will have access to the following information:`}</p>
    <ul>
      <li parentName="ul">{`the purposes of the processing;`}</li>
      <li parentName="ul">{`the categories of personal data concerned;`}</li>
      <li parentName="ul">{`the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organizations;`}</li>
      <li parentName="ul">{`where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period;`}</li>
      <li parentName="ul">{`the existence of the right to request from the Company rectification or erasure of personal data, or restriction of processing of personal data concerning you, or to object to such processing;`}</li>
      <li parentName="ul">{`the existence of the right to lodge a complaint with a supervisory authority;`}</li>
      <li parentName="ul">{`where the personal data are not collected directly from you, any available information as to their source; and`}</li>
    </ul>
    <p>{`If you wish to avail yourself of this right of access, you may at any time contact the responsible person as stated in section 1 of this Privacy Policy.`}</p>
    <p><strong parentName="p">{`6.3 Right to rectification`}</strong></p>
    <p>{`You have the right to obtain from the Company, without undue delay, the rectification of inaccurate personal data concerning you. Taking into account the purposes of the processing, you shall have the right to have incomplete personal data completed, including by means of providing a supplementary statement. If you wish to exercise this right to rectification, you may, at any time, contact the responsible person as stated in section 1 of this Privacy Policy.`}</p>
    <p><strong parentName="p">{`6.4 Right to erasure (right to be forgotten)`}</strong></p>
    <p>{`You have the right to obtain from the Company the erasure of personal data concerning you as soon as possible, and the Company shall have the obligation to erase personal data without undue delay where one of the following grounds applies:`}</p>
    <ul>
      <li parentName="ul">{`The personal data is no longer necessary in relation to the purposes for which they were collected or otherwise processed;`}</li>
      <li parentName="ul">{`The personal data has been unlawfully processed;`}</li>
      <li parentName="ul">{`The personal data must be erased for compliance with a legal obligation in accordance with the applicable law to which the Company is subject; and/or`}</li>
      <li parentName="ul">{`The personal data has been collected in relation to the offer of information society services.`}</li>
    </ul>
    <p>{`If any one of the aforementioned reasons applies, and you wish to request the erasure of personal data stored by the Company, you may at any time contact the responsible person as stated in section 1 of this Privacy Policy. The responsible person at the Company shall promptly ensure that the erasure request is complied with as soon as possible.`}</p>
    <p><strong parentName="p">{`6.5 Right to restriction of processing`}</strong></p>
    <p>{`You have the right to obtain from the Company restriction of processing where one of the following applies:`}</p>
    <ul>
      <li parentName="ul">{`the accuracy of the personal data is contested by you, for a period enabling the Company to verify the accuracy of the personal data;`}</li>
      <li parentName="ul">{`the processing is unlawful and you oppose the erasure of the personal data and requests instead the restriction of their use instead;`}</li>
      <li parentName="ul">{`the Company no longer needs the personal data for the purposes of the processing, but they are required by you for the establishment, exercise or defense of legal claims; and/or`}</li>
      <li parentName="ul">{`the data subject has objected to processing pending the verification whether the legitimate grounds of the Company override those of the data subject.`}</li>
    </ul>
    <p>{`If any one of the aforementioned conditions is met, and you wish to request the restriction of the processing of personal data stored by the Company, you may at any time contact the Company's responsible person. The responsible person will arrange the restriction of the processing.`}</p>
    <p><strong parentName="p">{`6.6 Right to object`}</strong></p>
    <p>{`You have the right to object, on grounds relating to your particular situation, at any time, to the processing of personal data concerning you. This also applies to profiling based on these provisions.`}</p>
    <p>{`The Company shall no longer process the personal data in the event of the objection, unless the Company can demonstrate reasonable grounds for the processing, which override the interests, rights and freedoms of you, or for the establishment, exercise or defense of legal claims. In order to exercise the right to object, you may directly contact the responsible person.`}</p>
    <p><strong parentName="p">{`6.7 Automated individual decision-making, including profiling`}</strong></p>
    <p>{`You have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you, or similarly significantly affects you, as long as the decision (1) is not necessary for entering into, or the performance of, a contract between you and the Company, or (2) is not authorized by the applicable law and which also lays down suitable measures to safeguard your rights and freedoms and legitimate interests, or (3) is not based on your explicit consent.`}</p>
    <p>{`If the decision (1) is necessary for entering into, or the performance of, a contract between you and the Company, or (2) it is based on your explicit consent, the Company shall implement suitable measures to safeguard your rights and freedoms and legitimate interests, at least the right to obtain human intervention on the part of the controller, to express their point of view and contest the decision.`}</p>
    <p>{`Please note that the Company does not use automatic decision-making but we may use profiling according to these Privacy Policy rules.`}</p>
    <p><strong parentName="p">{`6.8 Right to withdraw data protection consent`}</strong></p>
    <p>{`You have the right to withdraw your consent to processing of your personal data at any time.
If you wish to exercise the right to withdraw the consent, you may at any time directly contact the responsible person as stated in section 1.`}</p>
    <p><strong parentName="p">{`7. Duration of the storage`}</strong></p>
    <p>{`The Company will process and store the personal data of the data subject only for the period necessary to achieve the purpose of storage, or as far as this is granted by the applicable laws or regulations. If the storage purpose is not applicable, or if a storage period prescribed by the applicable laws expires, the personal data is routinely erased in accordance with the legal requirements.`}</p>
    <p><strong parentName="p">{`9. Updates to our Privacy Policy`}</strong></p>
    <p>{`The Company may update this Privacy Policy from time to time and inform you on the Website that the policy has been amended. The current version of the Privacy Policy, as published on our Website, is applicable. With each update to our policies, we will note which sections have been updated.`}</p>
    <p><strong parentName="p">{`10. More information about privacy regulations`}</strong></p>
    <p>{`For more information on applicable privacy regulations, you may refer
to:`}</p>
    <ul>
      <li parentName="ul">{`PDPA:
`}<a parentName="li" {...{
          "href": "https://www.pdpc.gov.sg/overview-of-pdpa/the-legislation/personal-data-protection-act"
        }}>{`https://www.pdpc.gov.sg/overview-of-pdpa/the-legislation/personal-data-protection-act`}</a></li>
    </ul>
    <p>{`Please do not hesitate to contact us if you have any questions regarding
this Privacy Policy by contacting us at `}<a parentName="p" {...{
        "href": "mailto:info@dpaerogels.com"
      }}>{`info@dpaerogels.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      